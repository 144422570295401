import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useAppConfig } from '@state';

const NotPermission = ({
  message = 'Sorry, this page does not exist.',
  showGoBackButton = true,
}) => {
  const [appConfig] = useAppConfig();
  const { showStudyList } = appConfig;

  return (
    <div className="flex h-full w-full items-center justify-center text-white">
      <div>
        <h5>No tiene permiso para ver este estudio</h5>
      </div>
    </div>
  );
};

NotPermission.propTypes = {
  message: PropTypes.string,
  showGoBackButton: PropTypes.bool,
};

export default NotPermission;
